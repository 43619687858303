import * as React from "react";
import styled from "styled-components";
import Logo from "../images/logo.png";
import LogoMobile from "../images/mini_logo.png";
import ALSLogoIcon from "../svg/ALSLogo";
import ALSLogoSmallIcon from "../svg/ALSLogoSmall";
import FacebookIcon from "../svg/Facebook";
import TwitterIcon from "../svg/Twitter";
import { Linkedin, Youtube } from "react-feather";
import DiscordIcon from "../svg/DiscordIcon";

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 0;
  align-items: center;
  height: max-content;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(360deg, rgba(32, 39, 70, 0) 0%, #202746 100%);
`;

const ContentWrapper = styled.div`
  gap: 84px;
  width: 100%;
  display: flex;
  padding: 32px;
  height: 100%;
  max-width: 1215px;
  @media (max-width: 900px) {
    padding: 32px 24px;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #f3f3f3;
  font-size: 14px;
  max-width: 400px;
  line-height: 24px;
`;

const LogoWrapperDesktop = styled.div`
  display: flex;
  height: max-content;
  @media (max-width: 900px) {
    display: none;
  }
`;

const LogoWrapperMobile = styled.div`
  display: none;
  @media (max-width: 900px) {
    height: max-conent;
    display: flex;
  }
`;

const SocialmediaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
  @media (max-width: 900px) {
    justify-content: flex-start;
  }
`;

const StyledContantAnchor = styled.a`
  text-decoration: none;
  color: #f3f3f3;
  :hover {
    opacity: 0.8;
  }
`;

const StyledBoldText = styled.div`
  display: flex;
  font-weight: bold;
  color: #f3f3f3;
  font-size: 14px;
`;

const Footer = () => (
  <MainWrapper>
    <ContentWrapper>
      <LogoWrapperDesktop>
        <ALSLogoIcon />
      </LogoWrapperDesktop>
      <LogoWrapperMobile>
        <ALSLogoSmallIcon />
      </LogoWrapperMobile>
      <Content>
        <StyledBoldText> Augmented Life Studio LLC</StyledBoldText>
        State of Delaware, 1013 Centre Road Suite 403-A,
        <br />
        Wilmington, county of Newcastle
      </Content>
      <Content>
        <StyledBoldText>Contact:</StyledBoldText>
        <StyledContantAnchor href="mailto:info@augmentedlife.studio">
          e-mail: info@augmentedlife.studio
        </StyledContantAnchor>
        <SocialmediaWrapper>
          <a
            href="https://twitter.com/metaproapp"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.youtube.com/channel/UCB3a-cqzegW1ly7FKd2oJAA"
            target="_blank"
            rel="noreferrer"
          >
            <Youtube color="#f3f3f3" />
          </a>
          <a
            href="https://discord.com/invite/WHQJs3d26a"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/metaproapp/"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin color="#f3f3f3" />
          </a>
        </SocialmediaWrapper>
      </Content>
    </ContentWrapper>
  </MainWrapper>
);

export default Footer;
